@import "global.scss";

.sections{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background: #000;
    z-index: 1;
    overflow: hidden;
    
}

.myToast{
    left: calc(50% - 75px) !important;
    width: 400px !important;
    max-width: 100% !important;
    background: #09ba06  !important;
    border: 1px solid #09ba06;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: row;
    >div{
        color: #fff;
        width: fit-content !important;
        display: flex !important;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row !important;
        font-size: 12px;
    }
    
}
.title{
    flex:0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include mobile_potrait{
        width: 90%;
    }
    h4{
        font-size: 1rem;
        color:#0ac6d4;
        margin-bottom: 10px;
        text-transform: uppercase;
        @include mobile_potrait{
            font-size: 0.8rem;
            text-align: center;
        }
        @include mobile_landscape{
            font-size: 0.6rem;
        }
    }
    h1{
        font-size: MIN(3vw, 40px);
        color: white;
        margin-bottom: 10px;
        @include mobile_potrait{
            font-size: 1.5rem;
            text-align: center;
        }
        @include mobile_landscape{
            font-size: 1.5rem;
        }
    }
    p{
        font-size: 1rem;
        color:#d9d9d9;
        width: 70%;
        text-align: center;
        line-height: 1.5;
        @include mobile_potrait{
            width: 100%;
            font-size: 12px;
        }
        @include mobile_landscape{
            font-size: 0.6rem;
        }
    }
}
.button{
    padding : 16px 20px;
    border: 1px solid #ff0099;
    border-radius: 8px;
    background: #00000000;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;
    @include mobile_potrait{
        font-size: 0.8rem;
        border-radius: 5px;
    }
    @include mobile_landscape{
        font-size: 0.8rem;
        margin-left: 1rem;
    }
    &:disabled{
        background: #00000000 !important;
        border-color: #626262 !important;
        box-shadow: 0px 0px 0px #ff0099;
        p{
            color: #626262 !important;
        }
    }
    &:hover{
        border-color: #f929a6;
        box-shadow: 0px 0px 5px #ff0099;
        p{
            color: #f929a6;
        }
    }
    
    p{
        z-index: 1;
        color: #ff0099;
        font-size: 15px;
        transition: all 0.3s ease;
        text-transform: uppercase;
        @include mobile_potrait{
            font-size: 0.8rem;
        }

    }
}
.effect1{
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.04;
    @include mobile_potrait{
        width: 30vw;
    }
}
.effect2{
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.04;
    @include mobile_potrait{
        width: 30vw;
    }
}