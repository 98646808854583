@import "../../global.scss";
.topbar{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top:0;
    z-index: 9;
    backdrop-filter: blur(5px);
    box-sizing: border-box;
    padding: 0 32px;
    @include mobile_potrait{
        padding: 0 24px;
    }
    
    .content{
        width: 100%;
        max-width: 1240px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        
        position: relative;
        @include mobile_potrait{
            padding-bottom: 32px;
        }
    }
    .logo{
        display: flex;
        align-items: center;
        justify-content: center;
        @include mobile_potrait{
        }
        
        a{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
           
            img{
                height: 100px;
                @include mobile_potrait{
                    height: 70px;
                }
                
            }
            
        }
    }
    .balance_div{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        margin-left: auto;
        @include mobile_potrait{
            width: 100%;
            justify-content: space-between;
            position: absolute;
            bottom: 8px;
        }

        .balance{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            position: relative;
            @include mobile_potrait{
            }
            
            p{
                color: #626262;
                font-size: MIN(1vw, 14px);
                text-transform: uppercase;
                text-shadow: 3px 3px 5px #000;
                font-weight: normal;
                z-index: 1;
                margin-right: 1rem;
                display: flex;
                align-items: center;
                letter-spacing: -1px;
                span{
                    color: #ff0099;
                    font-weight: 600;
                }
                @include mobile_potrait{
                    font-size: 0.6rem;
                    margin-right: 0.5rem;
                }
                img{
                    height: 24px;
                    margin-left: 8px;
                    @include mobile_potrait{
                        height: 18px;
                        margin-left: 5px;
                    }
                }
                
            }
            .mob_text{
                @include mobile_potrait{
                } 
            }
            
        }
        

    }
    .connectBtn{
        @include mobile_potrait{
            padding : 0.6rem 0.5rem;
        }
        
        p{
            @include mobile_potrait{
                font-size: 10px;
            }
        }
    }
    
    .hamburger{
        display: none;
        width: 2.5rem;
        height: 3rem;
        position: absolute;
        right : 2rem;
        top : 1rem;
        flex-direction: column;
        justify-content: space-around;
        cursor: pointer;
        z-index: 6;
        overflow: hidden;
        transform: scale(0.9);
        @include mobile_potrait{
            width: 2rem;
            height: 2rem;
            right : 0.2rem;
            top : 0.5rem;
            flex-direction: column;
            justify-content: space-around;
            cursor: pointer;
            z-index: 6;
            overflow: hidden;
            transform: scale(0.9);
        }
        @include mobile_landscape{
            width: 2.5rem;
            height: 2.2rem;
            right : 1rem;
            top : 1.3rem;
            flex-direction: column;
            justify-content: space-around;
            cursor: pointer;
            z-index: 6;
            overflow: hidden;
            transform: scale(0.9);
        }
        span{
            width: 100%;
            height: 0.3rem;
            border-radius: 3px;
            background-color: #fff;
            transform-origin: left;
            transition: all 1s ease;
        }
        &.active{
            z-index: 1;
            span{
                &:first-child{
                    width: 2.2rem;
                    transform: rotate(40deg);
                    @include mobile_potrait{
                        transform: rotate(35deg);
                    }
                    @include mobile_landscape{
                        transform: rotate(30deg);
                    }
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                    width: 2.2rem;
                    transform: rotate(-40deg);
                    @include mobile_potrait{
                        transform: rotate(-35deg);
                    }
                    @include mobile_landscape{
                        transform: rotate(-30deg);
                    }
                }

            }
        }
    }
}